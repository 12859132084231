// store.js
import { configureStore } from "@reduxjs/toolkit";
// import siteSlice from "./siteSlice";
import armadilloSlice from "./armadilloSlice";
import pringamosaSlice from "./pringamosaSlice";
import lorettaSlice from "./lorettaSlice";
// import ancestralSlice from "./ancestralSlice";
import ashkaSlice from "./ashkaSlice";

const store = configureStore({
  reducer: {
    // site: siteSlice,
    armadillo: armadilloSlice,
    pringamosa: pringamosaSlice,
    loretta: lorettaSlice,
    // ancestral: ancestralSlice,
    ashka: ashkaSlice,
  },
  // middleware: (getDefaultMiddleware) =>
  //   getDefaultMiddleware().concat(/* middleware extra */),
});

export default store;
