// lorettaSlice.js
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { siteConstants } from "../assets/constants/site.constants";
import fire from "../fire";
import { getFirestore, collection, addDoc } from "firebase/firestore";

// Thunk para enviar el formulario de contacto, utilizando async/await con try/catch
export const submitContactForm = createAsyncThunk(
  `${siteConstants.LORETTA3}/submitContactForm`,
  async ({ formData, mailTo }, { rejectWithValue }) => {
    try {
      const html = `
        <h1 style='color: #5e9ca0;'>Mensaje de <span style='color: #2b2301;'>${formData.name}</span> &lt;${formData.email}&gt;</h1>
        <h2 style='color: #2e6c80;'>Mensaje:</h2>
        <p>${formData.message}</p>
        <p><strong>Mensaje enviado desde su página web</strong></p>
      `;
      const payload = {
        ...mailTo,
        cc: formData.email,
        message: {
          ...mailTo.message,
          text: formData.message,
          html,
        },
        user: "LORETTA3",
      };
      const db = getFirestore(fire.auth().app);
      const docRef = await addDoc(collection(db, "mailTo"), payload);
      return { ...payload, id: docRef.id };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Thunk de ejemplo (fetch) que retorna un array vacío (puedes adaptarlo según sea necesario)
export const fetchData = createAsyncThunk(
  `${siteConstants.LORETTA3}/fetchData`,
  async (payload, { rejectWithValue }) => {
    try {
      // Implementa la lógica de fetch según tus necesidades
      return [];
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const initialState = {
  id: "loretta3",
  name: "Loretta3 | Soluciones IT",
  slogan: "..",
  type: "IT Company",
  title: "Desarrollo Web y Soluciones IT a Medida",
  seo: {
    title: "Loretta3 | Desarrollo Web y Transformación Digital",
    description:
      "Loretta3 transforma negocios con soluciones IT personalizadas: desarrollo web, software a medida, y consultoría en transformación digital. Aumenta tu competitividad con servicios tecnológicos innovadores.",
    keywords:
      "Loretta3, desarrollo web, creación de páginas web, desarrollo de software, soluciones IT, transformación digital, presencia digital, SEO, marketing digital, Kit Digital, automatización empresarial, páginas web Bogotá, consultoría IT, negocios digitales, Loretta Business Hub, software personalizado",
    url: "https://loretta3.com",
    image: "https://loretta3.com/logo.png",
    robots: "index, follow",
    author: "Loretta3 Soluciones IT",
    faviconUrls: {
      faviconIco: "/assets/images/favicons/loretta3/favicon.ico",
      favicon32: "/assets/images/favicons/loretta3/favicon-32x32.png",
      favicon16: "/assets/images/favicons/loretta3/favicon-16x16.png",
      appleTouchIcon: "/assets/images/favicons/loretta3/apple-touch-icon.png",
      android192: "/assets/images/favicons/loretta3/android-chrome-192x192.png",
      android512: "/assets/images/favicons/loretta3/android-chrome-512x512.png",
    },
  },
  nav: {
    home: { href: "post-section", label: "Loretta3 | IT Solutions" },
    contact: { href: "contact", label: "Contacto" },
  },
  post: {
    post: "loretta3",
    postOne: "IT",
    postTwo: "SOLUTIONS",
  },
  aboutSimple: {
    meta: { name: "description" },
    copy: "En Loretta3, potenciamos el crecimiento de empresas y comercios con herramientas digitales y consultoría estratégica, ofreciendo presencia digital, marketing digital y desarrollo de software a la medida para optimizar procesos, aumentar visibilidad y mejorar competitividad, impulsando la transformación digital con tecnología e innovación para maximizar impacto y rentabilidad.",
  },
  about: {
    aboutUs: {
      name: "Loretta3",
      desc: "Loretta3 es una empresa que potencia el crecimiento de otras organizaciones mediante la implementación de estrategias digitales innovadoras y el desarrollo de soluciones tecnológicas personalizadas. Comenzamos con la optimización de la presencia digital, seguido de marketing digital, y culminamos con el desarrollo de soluciones a la medida para fomentar su competitividad y eficiencia.",
    },
    mission: {
      name: "Misión",
      desc: "En Loretta3, nuestra misión es transformar digitalmente las empresas, proporcionándoles las herramientas necesarias para optimizar su presencia online, mejorar sus procesos internos y alcanzar el siguiente nivel en el mundo digital. Buscamos ser el aliado estratégico en el proceso de crecimiento empresarial a través de la innovación tecnológica.",
    },
    vision: {
      name: "Visión",
      desc: "Queremos ser reconocidos como un referente en la implementación de soluciones digitales personalizadas que ayuden a las empresas a destacar y crecer en el entorno digital. Nuestra visión es liderar el mercado en transformación digital y en el desarrollo de portales empresariales que optimicen la competitividad y eficiencia de nuestros clientes.",
    },
  },
  products: {
    "Presencia Digital": {
      desc: "Creación u optimización de la presencia digital, incluyendo página web, integración con redes sociales, SEO básico, geolocalización, campañas de marketing digital y gestión centralizada a través del portal de Loretta3.",
      description:
        "Enfocado en establecer o mejorar la visibilidad online de los negocios y gestionar su imagen digital desde un solo lugar.",
      features: [
        "Creación o renovación de la página web corporativa con diseño responsivo y certificado SSL.",
        "Integración con redes sociales (Facebook, Instagram, WhatsApp).",
        "SEO básico para optimizar la visibilidad en motores de búsqueda.",
        "Configuración en Google Maps, Waze y directorios digitales para geolocalización.",
        "Gestión de campañas de marketing digital personalizadas, con análisis y optimización de resultados.",
        "Administración centralizada a través del portal de Loretta3.",
      ],
      price: "$ 1'200.000 COP",
    },
    "Desarrollo a la Medida": {
      desc: "Soluciones personalizadas de software y aplicaciones para optimizar operaciones y procesos empresariales, con tecnología avanzada, automatización y escalabilidad.",
      description:
        "Desarrollo de software y aplicaciones personalizadas que optimizan procesos y potencian la eficiencia empresarial.",
      features: [
        "Software de gestión, automatización y monitoreo de operaciones.",
        "Aplicaciones móviles y web a la medida de las necesidades del cliente.",
        "Soluciones escalables, seguras y alineadas con los objetivos del negocio.",
        "Implementación ágil y soporte continuo.",
      ],
      price: "$ 5'000.000 COP",
    },
    "Consultoría IT": {
      desc: "Diagnóstico y asesoramiento estratégico en la transformación digital, implementación de sistemas, y soluciones tecnológicas a medida para mejorar la competitividad y eficiencia.",
      description:
        "Diagnóstico y asesoría estratégica para la transformación digital de las empresas.",
      features: [
        "Identificación de oportunidades de mejora en procesos digitales.",
        "Planificación e implementación de soluciones tecnológicas efectivas.",
        "Capacitación para el equipo interno en el uso de nuevas herramientas.",
        "Asesoramiento continuo para mantener la competitividad IT.",
      ],
      price: "$ 800.000 COP",
    },
  },
  customers: [
    "ancestral-cafe-galeria",
    "artesanias-armadillo",
    "ashka-soluciones-financieras",
  ],
  theme: {
    primaryColor: "#A30162",
    secondaryColor: "#230043",
    thirdColor: "#fb34ab",
    fourthColor: "#bbbbc7",
    linkColor: "#e7e7e7",
    backgroundColor: "#001c30",
    textColor: "#FFF",
    contactTextColor: "#001c30",
    urlLogo: "loretta3/logo_woutback.png",
    urlBack: "loretta3/back2.png",
    backgroundUrl:
      "url('https://firebasestorage.googleapis.com/v0/b/loretta3.firebasestorage.app/o/loretta3-back.mp4?alt=media&token=34565b9c-08e2-48ec-a707-a5f66387d10b')",
    postOne: {
      fontFamily: "'Nunito Sans', sans-serif",
      fontWeight: 800,
      margin: "0",
      textShadow:
        "0 0 5px #e0d9dd, 0 0 10px #d94bfc, 0 0 15px #fcf8f7, 0 0 20px #001c30",
      lineHeight: "1em",
    },
    postTwo: {
      fontFamily: "'Orbitron', sans-serif",
      margin: "0",
      color: "#001c30",
      textShadow:
        "0 0 5px #e0d9dd, 0 0 10px #d94bfc, 0 0 15px #fcf8f7, 0 0 20px #b1a6d2",
      fontWeight: 800,
    },
    postAbout: {
      backgroundColor: "rgba(0, 0, 0, 0.2)",
      color: "#FFF",
    },
  },
  company: {
    name: "loretta3",
    whatsapp: "573005165832",
  },
  socialLinks: {
    float: {
      whatsapp:
        "https://api.whatsapp.com/send?phone=3005165832&text=Hola%21%20Quiero%20m%C3%A1s%20informaci%C3%B3n",
    },
    contact: {
      facebook: "https://www.facebook.com/loretta3.it",
      instagram: "https://www.instagram.com/loretta3_it/",
      linkedin: "https://www.linkedin.com/company/loretta3",
      twitter: "https://x.com/LorenaSerr89731",
      youtube: "https://www.youtube.com/@Loretta3-it",
      tiktok: "https://www.tiktok.com/",
      google: "https://maps.app.goo.gl/YxW8QMWUKz1jmDFQ7",
      pinterest: "https://www.pinterest.com/loretta3_it",
    },
    footer: {
      facebook: "https://www.facebook.com/loretta3.it",
      instagram: "https://www.instagram.com/loretta3_it/",
      linkedin: "https://www.linkedin.com/company/loretta3",
      twitter: "https://x.com/LorenaSerr89731",
      youtube: "https://www.youtube.com/@Loretta3-it",
      tiktok: "https://www.tiktok.com/",
      google: "https://maps.app.goo.gl/YxW8QMWUKz1jmDFQ7",
      pinterest: "https://www.pinterest.com/loretta3_it",
      "map-marker": "https://maps.app.goo.gl/YxW8QMWUKz1jmDFQ7",
    },
    locationEmbed:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3974.4856180322868!2d-74.00551360000001!3d5.0246938!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e4071a26e00fdc5%3A0xd0815335e779510e!2sLoretta3!5e0!3m2!1ses-419!2sco!4v1740687985797!5m2!1ses-419!2sco",
  },
  mailTo: {
    to: "lorenita.serrano@gmail.com",
    cc: "",
    bcc: "",
    message: {
      subject: "Contacto desde página web",
      text: "",
      html: "",
    },
  },
  contactForm: {
    name: "",
    email: "",
    phone: "",
    message: "",
  },
  message: null,
  loading: false,
  error: null,
};

const lorettaSlice = createSlice({
  name: "loretta",
  initialState,
  reducers: {
    init(state) {
      state.contactForm = { name: "", email: "", phone: "", message: "" };
      state.message = null;
      state.error = null;
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchData.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchData.fulfilled, (state) => {
        state.loading = false;
        state.message = "Mensaje enviado. Pronto nos comunicaremos contigo";
      })
      .addCase(fetchData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || action.error.message;
      })
      .addCase(submitContactForm.pending, (state) => {
        state.loading = true;
      })
      .addCase(submitContactForm.fulfilled, (state) => {
        state.loading = false;
        state.contactForm = { name: "", email: "", phone: "", message: "" };
        state.message = "Mensaje enviado. Pronto nos comunicaremos contigo";
      })
      .addCase(submitContactForm.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || action.error.message;
      });
  },
});

export const { init } = lorettaSlice.actions;
export default lorettaSlice.reducer;
