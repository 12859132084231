export const siteConstants = {
  HOME: "loretta3",
  ABOUT: "Nosotros",
  PRODUCTS: "Portafolio",
  CONTACT: "Contactenos",

  WHITE_COLOR_RGBA: "rgba(255, 255, 255, 0.5)",
  WHITE_COLOR_RGB: "rgb(255, 255, 255)",
  VIOLET_FLOWER_COLOR: "rgba(35, 0, 67, 0.5)",
  MAGENTA_COLOR_RGB: "rgb(163,1,98)",
  MAGENTA_COLOR_RGBA: "rgb(163,1,98, 0.5)",

  DARK_BLUE_COLOR_RGB: "rgb(0, 28, 48)",

  LORETTA3: "loretta3",
  ANCESTRAL: "ancestral-cafe-galeria",
  ARMADILLO: "artesanias-armadillo",
  ASHKA: "ashka-soluciones-financieras",
  PRINGAMOSA: "la-pringamosa",
  LORK: "lork",
};
