// lorettaSlice.js
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { siteConstants } from "../assets/constants/site.constants";
import fire from "../fire";
import { getFirestore, collection, addDoc } from "firebase/firestore";

// Thunk para enviar el formulario de contacto, utilizando async/await con try/catch
export const submitContactForm = createAsyncThunk(
  `${siteConstants.ASHKA}/submitContactForm`,
  async ({ formData, mailTo }, { rejectWithValue }) => {
    try {
      const html = `
        <h1 style='color: #5e9ca0;'>Mensaje de <span style='color: #2b2301;'>${formData.name}</span> &lt;${formData.email}&gt;</h1>
        <h2 style='color: #2e6c80;'>Mensaje:</h2>
        <p>${formData.message}</p>
        <p><strong>Mensaje enviado desde su página web</strong></p>
      `;
      const payload = {
        ...mailTo,
        cc: formData.email,
        message: {
          ...mailTo.message,
          text: formData.message,
          html,
        },
        user: "ASHKA",
      };
      const db = getFirestore(fire.auth().app);
      const docRef = await addDoc(collection(db, "mailTo"), payload);
      return { ...payload, id: docRef.id };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Thunk de ejemplo (fetch) que retorna un array vacío (puedes adaptarlo según sea necesario)
export const fetchData = createAsyncThunk(
  `${siteConstants.ASHKA}/fetchData`,
  async (payload, { rejectWithValue }) => {
    try {
      // Implementa la lógica de fetch según tus necesidades
      return [];
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const initialState = {
  id: "ashka",
  name: "ASHKA | Soluciones Financieras",
  type: "Gestión de creditos",
  title: "Créditos para Pensionados, Empleados Públicos y Reportados",
  slogan: {
    one: "ASHKA",
    two: "¡Créditos Fáciles, Soluciones Reales!",
  },
  seo: {
    title: "template | Desarrollo Web y Transformación Digital",
    description:
      "template transforma negocios con soluciones IT personalizadas: desarrollo web, software a medida, y consultoría en transformación digital. Aumenta tu competitividad con servicios tecnológicos innovadores.",
    keywords:
      "créditos para pensionados, créditos para reportados,préstamos para empleados públicos,crédito fácil y rápido,financiamiento sin papeleo,préstamos con historial negativo,dinero inmediato para pensionados,préstamos sin historial crediticio,créditos para personas en Datacrédito,crédito con descuento de nómina",
    url: "https://loretta3.com/landing/ashka-soluciones-financieras",
    image: "https://loretta3.com/images/ashka.png",
    robots: "index, follow",
    author: "Loretta3 Soluciones IT",
    faviconUrls: {
      faviconIco: "/assets/images/favicons/ashka/favicon.ico",
      favicon32: "/assets/images/favicons/ashka/favicon-32x32.png",
      favicon16: "/assets/images/favicons/ashka/favicon-16x16.png",
      appleTouchIcon: "/assets/images/favicons/ashka/apple-touch-icon.png",
      android192: "/assets/images/favicons/ashka/android-chrome-192x192.png",
      android512: "/assets/images/favicons/ashka/android-chrome-512x512.png",
    },
  },
  nav: {
    home: { href: "post-section", label: "Ashka | Soluciones financieras" },
    products: { href: "products", label: "Servicios" },
    contact: { href: "contact", label: "Contacto" },
  },
  post: {
    post: "ASHKA",
    postOne: "Soluciones",
    postTwo: "Financieras",
  },
  aboutSimple: {
    meta: {
      name: "description",
    },
    copy: "En ASHKA Soluciones Financieras, entendemos que cada persona tiene necesidades y sueños financieros únicos. Nos dedicamos a ofrecerte una experiencia personalizada, sin complicaciones, y con la asesoría profesional que necesitas para encontrar el crédito adecuado a tu perfil. Ya sea para un crédito hipotecario, de consumo, libranza, o para la compra de un vehículo, estamos aquí para acompañarte en cada paso del proceso.",
  },
  about: {
    Nosotros: {
      name: "Ashka",
      desc: "En ASHKA Soluciones Financieras, entendemos que cada persona tiene necesidades y sueños financieros únicos. Nos dedicamos a ofrecerte una experiencia personalizada, sin complicaciones, y con la asesoría profesional que necesitas para encontrar el crédito adecuado a tu perfil. Ya sea para un crédito hipotecario, de consumo, libranza, o para la compra de un vehículo, estamos aquí para acompañarte en cada paso del proceso.",
    },
    Misión: {
      name: "Misión",
      desc: "Nuestra misión es brindarte un servicio ágil, seguro y transparente, facilitando el acceso a las mejores opciones de financiamiento. Nos enfocamos en ofrecerte una asesoría gratuita, sin comisiones, con el objetivo de guiarte hacia el crédito que más se ajuste a tus necesidades. Sabemos que el tiempo es esencial y trabajamos para que tu solicitud de crédito se resuelva de manera rápida y eficiente.",
    },
    Visión: {
      name: "Visión",
      desc: "Queremos ser reconocidos como un referente en la implementación de soluciones financieras personalizadas",
    },
  },
  products: {
    "Crédito Hipotecario": {
      nombre: "Crédito Hipotecario",
      descripcion:
        "Financiamiento para la compra, remodelación o ampliación de vivienda con tasas competitivas y plazos flexibles.",
      requisitos: {
        edad_minima: 18,
        edad_maxima: 70,
        monto_minimo: 10000000,
        ingreso_minimo: "1 salario mínimo",
        documentos: [
          "Certificado laboral no mayor a 30 días",
          "Comprobantes de pago último mes o dos últimas quincenas",
          "Extractos bancarios de los últimos 3 meses",
        ],
      },
    },
    "Crédito de Consumo o Libre Inversión": {
      nombre: "Crédito de Consumo o Libre Inversión",
      descripcion:
        "Préstamos para cualquier necesidad personal, con opciones flexibles y aprobación rápida.",
      requisitos: {
        edad_minima: 18,
        edad_maxima: 70,
        monto_minimo: 10000000,
        ingreso_minimo: "1 salario mínimo",
        documentos: [
          "Certificado laboral",
          "Últimos comprobantes de pago",
          "Extractos bancarios recientes",
        ],
      },
    },
    "Compra de Cartera": {
      nombre: "Compra de Cartera",
      descripcion:
        "Unificación de deudas para mejorar la liquidez y reducir tasas de interés.",
      requisitos: {
        edad_minima: 18,
        edad_maxima: 70,
        monto_minimo: 10000000,
        ingreso_minimo: "1 salario mínimo",
        documentos: [
          "Certificación de las deudas actuales",
          "Extractos bancarios recientes",
          "Comprobantes de pago",
        ],
      },
    },
    "Créditos para Pensionados": {
      nombre: "Créditos para Pensionados",
      descripcion:
        "Opciones de financiamiento con descuentos por nómina y plazos extendidos para pensionados.",
      requisitos: {
        edad_minima: 18,
        edad_maxima: 86,
        monto_minimo: 10000000,
        ingreso_minimo: "1 salario mínimo",
        documentos: [
          "Último comprobante de pago de pensión",
          "Extractos bancarios recientes",
        ],
      },
    },
    "Créditos para Compra de Vehículo": {
      nombre: "Créditos para Compra de Vehículo",
      descripcion:
        "Financiación para la adquisición de vehículos nuevos o usados con tasas preferenciales.",
      requisitos: {
        edad_minima: 18,
        edad_maxima: 70,
        monto_minimo: 10000000,
        ingreso_minimo: "1 salario mínimo",
        documentos: [
          "Certificado laboral o pensional",
          "Extractos bancarios recientes",
          "Comprobantes de pago",
        ],
      },
    },
  },
  customers: [""],
  theme: {
    primaryColor: "#001c30", // Color principal para botones y elementos destacados
    secondaryColor: "#433200", // Complementario para detalles
    thirdColor: "#2c0119", // Para resaltar títulos y hover (por ejemplo, links o botones)
    fourthColor: "#f4f4f2", // Fallback
    linkColor: "#FFF", // Enlaces
    navlinkColor: "#f8f9fa", // Enlaces
    backgroundColor: "#FFD230", // Fondo principal (amarillo característico)
    textColor: "#490404", // Texto en blanco
    contactTextColor: "#490404", // Texto en blanco
    postTextColor: "#FFF", // Texto en blanco
    backgroundUrl:
      "url('https://firebasestorage.googleapis.com/v0/b/loretta3.firebasestorage.app/o/pringamosa-back-2.mp4?alt=media&token=f738d85d-bcba-4173-bc27-e0e9049bac16')",
    postOne: {
      fontFamily: "'Abang', sans-serif",
      fontWeight: 800,
      margin: "0",
      textShadow: "0 4px 20px rgba(24, 3, 83, 0.8)",
      lineHeight: "1em",
    },
    urlLogo: "template/logo_woutback.png",
    urlBack: "template/back2.png",

    postTwo: {
      fontFamily: "'Orbitron', sans-serif",
      margin: "0",
      color: "#b2ebff",
      textShadow: "0 4px 20px rgb(16, 107, 20)",
      fontWeight: 800,
    },
    postAbout: {
      backgroundColor: "rgba(0, 0, 0, 0.2)",
      color: "#FFF",
    },
  },
  company: {
    name: "ashka",
    whatsapp: "573152912982",
  },
  socialLinks: {
    float: {
      whatsapp:
        "https://api.whatsapp.com/send?phone=3152912982&text=Hola%21%20Quiero%20m%C3%A1s%20informaci%C3%B3n",
    },
    contact: {
      facebook: "https://www.facebook.com/template.it",
      instagram: "https://www.instagram.com/template_it/",
      linkedin: "https://www.linkedin.com/company/template",
      twitter: "https://x.com/LorenaSerr89731",
      youtube: "https://www.youtube.com/@template-it",
      tiktok: "https://www.tiktok.com/",
      google: "https://maps.app.goo.gl/YxW8QMWUKz1jmDFQ7",
      pinterest: "https://www.pinterest.com/template_it",
    },
    footer: {
      facebook: "https://www.facebook.com/ashkasoluciones",
      instagram: "https://www.instagram.com/ashkasoluciones",
      //   linkedin: "https://www.linkedin.com/company/loretta3",
      //   twitter: "https://x.com/LorenaSerr89731",
      //   youtube: "https://www.youtube.com/@Loretta3-it",
      tiktok: "https://www.tiktok.com/@ashka.soluciones",
      //   google: "https://maps.app.goo.gl/YxW8QMWUKz1jmDFQ7",
      //   pinterest: "https://www.pinterest.com/template_it",
      //   "map-location-dot": "https://maps.app.goo.gl/YxW8QMWUKz1jmDFQ7",
    },
    locationEmbed:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7949.022718332881!2d-74.00997202228999!3d5.020471499999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e40702156046de9%3A0xe1fbf186467e4491!2sAshka%20Soluciones%20Financieras!5e0!3m2!1ses-419!2sco!4v1740725475178!5m2!1ses-419!2sco",
  },
  mailTo: {
    to: "ashka.soluciones@gmail.com",
    cc: "",
    bcc: "",
    message: {
      subject: "Contacto desde página web",
      text: "",
      html: "",
    },
  },
  contactForm: {
    name: "",
    email: "",
    phone: "",
    message: "",
  },
  testimonies: {
    id1: {
      nombre: "Carlos Ramírez",
      tipo_credito: "Crédito de Consumo",
      comentario:
        "ASHKA me ayudó a obtener el crédito que necesitaba en tiempo récord. La asesoría fue excelente y sin costos adicionales. ¡Muy recomendados!",
      calificacion: 5,
    },
    id2: {
      nombre: "María González",
      tipo_credito: "Crédito Hipotecario",
      comentario:
        "Gracias a ASHKA pude comprar mi casa sin complicaciones. Me guiaron en todo el proceso y me ofrecieron la mejor opción de financiamiento.",
      calificacion: 5,
    },
    id3: {
      nombre: "Julián Herrera",
      tipo_credito: "Compra de Cartera",
      comentario:
        "Estaba agobiado con varias deudas y ASHKA me ayudó a consolidarlas con una tasa más baja. Ahora manejo mejor mis finanzas. ¡Excelente servicio!",
      calificacion: 5,
    },
    id4: {
      nombre: "Sandra López",
      tipo_credito: "Crédito para Pensionados",
      comentario:
        "Como pensionada, pensé que sería difícil obtener un crédito, pero ASHKA me brindó una solución rápida y sin trámites complicados. ¡Muy agradecida!",
      calificacion: 5,
    },
    id5: {
      nombre: "Fernando Martínez",
      tipo_credito: "Crédito para Vehículo",
      comentario:
        "ASHKA hizo posible que comprara mi carro sin problemas. El proceso fue ágil y me ofrecieron la mejor opción del mercado.",
      calificacion: 5,
    },
  },
  message: null,
  loading: false,
  error: null,
};

const ashkaSlice = createSlice({
  name: "ashka",
  initialState,
  reducers: {
    init(state) {
      state.contactForm = { name: "", email: "", phone: "", message: "" };
      state.message = null;
      state.error = null;
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchData.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchData.fulfilled, (state) => {
        state.loading = false;
        state.message = "Mensaje enviado. Pronto nos comunicaremos contigo";
      })
      .addCase(fetchData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || action.error.message;
      })
      .addCase(submitContactForm.pending, (state) => {
        state.loading = true;
      })
      .addCase(submitContactForm.fulfilled, (state) => {
        state.loading = false;
        state.contactForm = { name: "", email: "", phone: "", message: "" };
        state.message = "Mensaje enviado. Pronto nos comunicaremos contigo";
      })
      .addCase(submitContactForm.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || action.error.message;
      });
  },
});

export const { init } = ashkaSlice.actions;
export default ashkaSlice.reducer;
