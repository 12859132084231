// armadilloSlice.js
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { siteConstants } from "../assets/constants/site.constants";
import fire from "../fire";
import { getFirestore, collection, addDoc } from "firebase/firestore";

// Thunk for fetching data (currently returns an empty array)
export const fetchData = createAsyncThunk(
  `${siteConstants.CUST_ARMADILLO}/fetchData`,
  async (payload, { rejectWithValue }) => {
    try {
      // Implementa la lógica de fetch según sea necesario
      return [];
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Thunk for submitting the contact form using async/await with try/catch
export const submitContactForm = createAsyncThunk(
  `${siteConstants.ARMADILLO}/submitContactForm`,
  async ({ formData, mailTo }, { rejectWithValue }) => {
    try {
      const html = `
        <h1 style='color: #5e9ca0;'>Mensaje de <span style='color: #2b2301;'>${formData.name}</span> &lt;${formData.email}&gt;</h1>
        <h2 style='color: #2e6c80;'>Mensaje:</h2>
        <p>${formData.message}</p>
        <p><strong>Mensaje enviado desde su página web</strong></p>
        <p><strong>&nbsp;</strong></p>
      `;
      const payloadData = {
        ...mailTo,
        cc: formData.email,
        message: {
          ...mailTo.message,
          text: formData.message,
          html: html,
        },
        user: "ARMADILLO",
      };
      const db = getFirestore(fire.auth().app);
      const docRef = await addDoc(collection(db, "mailTo"), payloadData, {
        merge: true,
      });
      return { ...payloadData, id: docRef.id };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const initialState = {
  id: "artesanias-armadillo",
  name: "Artesanías Armadillo",
  type: "Artesanias",
  title: "Correaje y Cinturones de Cuero Hechos a Mano",
  seo: {
    title:
      "Artesanías Armadillo - Cinturones y Correajes de Cuero Artesanales Hechos a Mano",
    description:
      "Compra correas y cinturones de cuero personalizados, hechos a mano con materiales de alta calidad. Durabilidad y estilo únicos en Artesanías Armadillo.",
    keywords:
      "correas de cuero, cinturones personalizados, artesanía en cuero, productos artesanales Bogotá, cinturones de cuero hechos a mano, artículos de cuero Bogotá, correas de cuero personalizadas, cuero legítimo, cinturones de cuero Cundinamarca, artesanías colombianas, productos hechos a mano Bogotá, accesorios de cuero, correas de cuero Bogotá, cinturones de cuero Cundinamarca, artesanía local Bogotá, regalos personalizados cuero",
    url: "https://loretta3.com/landing/artesanias-armadillo",
    image: "https://loretta3.com/images/armadillo.png",
    robots: "index, follow",
    author: "Loretta3",
  },
  nav: {
    home: { href: "post-section", label: "Artesanias Armadillo" },
    about: { href: "about-us", label: "Nosotros" },
    products: { href: "menu", label: "Productos" },
    contact: { href: "contact", label: "Contacto" },
  },
  post: {
    post: "Armadillo",
    postOne: "Artesanías",
  },
  slogan: {
    one: "Artesanías Armadillo",
    two: "Creaciones hechas a mano, únicas como tú",
  },
  about: {
    ourPhilosophy: {
      desc: "Creemos en el valor de lo hecho a mano. Cada artículo que elaboramos es el resultado de la fusión entre técnicas tradicionales y un diseño contemporáneo, lo que nos permite ofrecer productos exclusivos, duraderos y totalmente personalizables. Nos esforzamos por crear piezas que no solo complementen tu día a día, sino que también cuenten una historia de autenticidad y calidad.",
    },
    qualityAndPersonalization: {
      desc: "Utilizamos materiales de la más alta calidad, garantizando que cada accesorio no solo destaque por su belleza, sino también por su resistencia y funcionalidad. Desde cinturones y correas para mochilas, hasta accesorios para mascotas y equipos, en cada creación se refleja el cuidado y dedicación de nuestro equipo, comprometido a transformar cada detalle en una obra de arte.",
    },
    ourCommitment: {
      desc: "En Artesanías Armadillo, cada producto es único, al igual que tú. Nuestro compromiso es ofrecerte la posibilidad de personalizar tu accesorio, para que puedas llevar contigo un pedazo de arte que se adapte a tu estilo y necesidades. Con cada pieza, buscamos reafirmar nuestro eslogan: 'Artesanía hecha a mano, calidad que perdura.'",
    },
  },
  aboutSimple: {
    meta: { name: "description" },
    copy: "En Artesanías Armadillo, cada pieza es una manifestación de tradición, pasión y creatividad. Somos una empresa dedicada a la fabricación de correas tejidas a mano y accesorios artesanales en cuero y tejido, diseñados para realzar el estilo único de personas, mascotas y equipos. Te invitamos a descubrir la diferencia de lo auténtico. Acompáñanos en este viaje de creatividad y tradición, y encuentra ese accesorio especial que refleje verdaderamente quién eres.",
  },
  catalog: {
    "Moda y Estilo": {
      desc: "Accesorios pensados para complementar el look diario y expresar personalidad. Diseñados para realzar tu estilo con productos hechos a mano y disponibles en tallas inclusivas.",
      foto: "ruta/imagenes/moda_y_estilo.jpg",
      productList: [
        {
          id: "MS-001",
          nombre: "Cinturones Personalizados",
          slug: "cinturones-personalizados",
          description:
            "Cinturones tejidos y de cuero hechos a mano, ajustables y disponibles en diseños únicos que combinan estilo, durabilidad y opciones inclusivas para todos los cuerpos.",
          foto: "ruta/imagenes/cinturones_personalizados.jpg",
          precio: { COP: 120000, USD: 30 },
          stock: 50,
          tags: [
            "cinturones",
            "personalizados",
            "cuero",
            "tejido",
            "tallas inclusivas",
          ],
          customizable: true,
          materials: "Cuero genuino, tejido artesanal",
          dimensions: "Ajustable a varias medidas",
          tallasInclusivas: true,
        },
        {
          id: "MS-002",
          nombre: "Llaveros Personalizados",
          slug: "llaveros-personalizados",
          description:
            "Llaveros pequeños y únicos que combinan detalles tejidos y de cuero, ideales como accesorio o regalo, con opciones personalizables para un toque único.",
          foto: "ruta/imagenes/llaveros_personalizados.jpg",
          precio: { COP: 50000, USD: 12 },
          stock: 100,
          tags: ["llaveros", "personalizados", "accesorio", "cuero", "tejido"],
          customizable: true,
          materials: "Cuero genuino, detalles tejidos",
          dimensions: "Tamaño estándar",
          tallasInclusivas: false,
        },
      ],
    },
    "Viajes y Equipos": {
      desc: "Productos diseñados para acompañarte en tus aventuras diarias, ya sea en viajes, en el trabajo o en tus proyectos creativos.",
      foto: "ruta/imagenes/viajes_y_equipos.jpg",
      productList: [
        {
          id: "VE-001",
          nombre: "Correas para Cámaras",
          slug: "correas-para-camaras",
          description:
            "Correas cómodas y resistentes, diseñadas para cámaras, combinando tejido artesanal con detalles en cuero. Perfectas para fotógrafos que exigen calidad y estilo.",
          foto: "ruta/imagenes/correas_para_camaras.jpg",
          precio: { COP: 110000, USD: 28 },
          stock: 30,
          tags: [
            "correas",
            "cámaras",
            "accesorios fotográficos",
            "personalizados",
          ],
          customizable: true,
          materials: "Cuero genuino, tejido artesanal",
          dimensions: "Ajustable",
          tallasInclusivas: false,
        },
        {
          id: "VE-002",
          nombre: "Correas para Mochilas",
          slug: "correas-para-mochilas",
          description:
            "Correas reforzadas para mochilas, confeccionadas con tejido de alta resistencia y acabados en cuero, ideales para viajeros, estudiantes y aventureros.",
          foto: "ruta/imagenes/correas_para_mochilas.jpg",
          precio: { COP: 110000, USD: 28 },
          stock: 40,
          tags: ["correas", "mochilas", "viajes", "personalizados"],
          customizable: true,
          materials: "Cuero genuino, tejido artesanal",
          dimensions: "Ajustable",
          tallasInclusivas: false,
        },
        {
          id: "VE-003",
          nombre: "Correas para Bolsos",
          slug: "correas-para-bolsos",
          description:
            "Correas elegantes y funcionales para bolsos, hechas a mano con materiales duraderos y un diseño único para complementar cualquier outfit.",
          foto: "ruta/imagenes/correas_para_bolsos.jpg",
          precio: { COP: 110000, USD: 28 },
          stock: 35,
          tags: ["correas", "bolsos", "accesorios", "personalizados"],
          customizable: true,
          materials: "Cuero genuino, tejido artesanal",
          dimensions: "Ajustable",
          tallasInclusivas: false,
        },
      ],
    },
    Mascotas: {
      desc: "Accesorios artesanales que aseguran que tus compañeros peludos luzcan únicos y seguros. Diseñados pensando en la comodidad y el estilo de tu mascota.",
      foto: "ruta/imagenes/mascotas.jpg",
      productList: [
        {
          id: "MAS-001",
          nombre: "Collares Personalizados",
          slug: "collares-personalizados",
          description:
            "Collares ajustables para mascotas, disponibles en diseños exclusivos que combinan tejido y cuero, con opción de personalización para incluir el nombre y contacto del dueño.",
          foto: "ruta/imagenes/collares_personalizados.jpg",
          precio: { COP: 120000, USD: 30 },
          stock: 25,
          tags: [
            "collares",
            "mascotas",
            "personalizados",
            "accesorios para mascotas",
          ],
          customizable: true,
          materials: "Cuero genuino, tejido artesanal",
          dimensions: "Ajustable",
          tallasInclusivas: false,
        },
      ],
    },
    "Instrumentos Musicales": {
      desc: "Correas artesanales diseñadas para instrumentos musicales, ofreciendo resistencia, comodidad y estilo en el escenario. Ideal para guitarras, bajos y otros instrumentos.",
      foto: "ruta/imagenes/instrumentos_musicales.jpg",
      productList: [
        {
          id: "IM-001",
          nombre: "Correas para Instrumentos Musicales",
          slug: "correas-para-instrumentos-musicales",
          description:
            "Correas artesanales diseñadas especialmente para guitarras, bajos y otros instrumentos, ofreciendo resistencia, comodidad y un toque único para músicos profesionales y aficionados.",
          foto: "ruta/imagenes/correas_para_instrumentos.jpg",
          precio: { COP: 130000, USD: 33 },
          stock: 20,
          tags: [
            "correas",
            "instrumentos musicales",
            "guitarras",
            "bajos",
            "personalizadas",
          ],
          customizable: true,
          materials: "Cuero genuino, tejido artesanal",
          dimensions: "Ajustable",
          tallasInclusivas: false,
        },
      ],
    },
    "Personalización y Regalos": {
      desc: "Opciones personalizadas para crear detalles únicos, perfectos para obsequios o para lucir un estilo propio. Cada pieza es un reflejo de creatividad y dedicación.",
      foto: "ruta/imagenes/personalizacion_y_regalos.jpg",
      productList: [
        {
          id: "PR-001",
          nombre: "Diseños a Medida",
          slug: "disenos-a-medida",
          description:
            "Productos hechos a mano adaptados a tus preferencias en colores, tamaños y estilos, ideales para quienes buscan exclusividad y personalización en cada detalle.",
          foto: "ruta/imagenes/disenos_a_medida.jpg",
          precio: { COP: 120000, USD: 30 },
          stock: 30,
          tags: [
            "diseños a medida",
            "personalizados",
            "accesorios",
            "artesanales",
          ],
          customizable: true,
          materials: "Cuero genuino, tejido artesanal",
          dimensions: "Personalizado según requerimientos",
          tallasInclusivas: false,
        },
        {
          id: "PR-002",
          nombre: "Regalos Personalizados",
          slug: "regalos-personalizados",
          description:
            "Accesorios tejidos y de cuero, diseñados especialmente como obsequios únicos y significativos, perfectos para sorprender en cualquier ocasión.",
          foto: "ruta/imagenes/regalos_personalizados.jpg",
          precio: { COP: 90000, USD: 23 },
          stock: 40,
          tags: ["regalos", "personalizados", "accesorios", "artesanales"],
          customizable: true,
          materials: "Cuero genuino, tejido artesanal",
          dimensions: "Tamaño estándar",
          tallasInclusivas: false,
        },
      ],
    },
    "Colecciones Exclusivas": {
      desc: "Ediciones limitadas y colaboraciones especiales que resaltan la creatividad y el trabajo conjunto con artistas o temáticas culturales, ofreciendo piezas únicas con historia.",
      foto: "ruta/imagenes/colecciones_exclusivas.jpg",
      productList: [
        {
          id: "CE-001",
          nombre: "Ediciones Temáticas (bandas musicales, superhéroes, etc.)",
          slug: "ediciones-tematicas",
          description:
            "Colecciones limitadas inspiradas en temporadas, eventos o tradiciones culturales, ideales para quienes buscan productos únicos y llenos de historia.",
          foto: "ruta/imagenes/ediciones_tematicas.jpg",
          precio: { COP: 140000, USD: 35 },
          stock: 15,
          tags: [
            "ediciones temáticas",
            "colecciones",
            "limitadas",
            "artesanales",
          ],
          customizable: false,
          materials: "Cuero genuino, tejido artesanal",
          dimensions: "Variado",
          tallasInclusivas: false,
        },
        {
          id: "CE-002",
          nombre: "Colaboraciones Exclusivas",
          slug: "colaboraciones-exclusivas",
          description:
            "Productos diseñados en conjunto con artistas o marcas locales, únicos y llenos de estilo, perfectos para coleccionistas y amantes de lo exclusivo.",
          foto: "ruta/imagenes/colaboraciones_exclusivas.jpg",
          precio: { COP: 150000, USD: 38 },
          stock: 10,
          tags: ["colaboraciones", "exclusivas", "artesanales", "limitadas"],
          customizable: false,
          materials: "Cuero genuino, tejido artesanal",
          dimensions: "Variado",
          tallasInclusivas: false,
        },
      ],
    },
  },
  products: {
    "Moda y Estilo": [
      {
        nombre: "Cinturones Personalizados",
        description:
          "Cinturones tejidos y de cuero hechos a mano, ajustables y disponibles en diseños únicos que combinan estilo y durabilidad.",
        precio: { COP: 100000, USD: 25 },
      },
      {
        nombre: "Llaveros Personalizados",
        description:
          "Llaveros pequeños y únicos que combinan detalles tejidos y de cuero, ideales como accesorio o regalo.",
        precio: { COP: 40000, USD: 10 },
      },
    ],
    "Viajes y Equipos": [
      {
        nombre: "Correas para Cámaras",
        description:
          "Correas cómodas y resistentes, diseñadas para cámaras, combinando tejido artesanal con detalles en cuero.",
        precio: { COP: 100000, USD: 25 },
      },
      {
        nombre: "Correas para Mochilas",
        description:
          "Correas reforzadas para mochilas, confeccionadas con tejido de alta resistencia y acabados en cuero.",
        precio: { COP: 100000, USD: 25 },
      },
      {
        nombre: "Correas para Bolsos",
        description:
          "Correas elegantes y funcionales para bolsos, hechas a mano con materiales duraderos y un diseño único.",
        precio: { COP: 100000, USD: 25 },
      },
    ],
    Mascotas: [
      {
        nombre: "Collares Personalizados",
        description:
          "Collares ajustables para mascotas, disponibles en diseños exclusivos que combinan tejido y cuero. Estos llevan nombre de la mascota, y datos de contacto del dueño.",
        precio: { COP: 100000, USD: 25 },
      },
    ],
    "Personalización y Regalos": [
      {
        nombre: "Diseños a Medida",
        description:
          "Productos hechos a mano adaptados a tus preferencias en colores, tamaños y estilos.",
        precio: { COP: 100000, USD: 25 },
      },
      {
        nombre: "Regalos Personalizados",
        description:
          "Accesorios tejidos y de cuero, diseñados especialmente como obsequios únicos y significativos.",
        precio: { COP: 100000, USD: 25 },
      },
    ],
    "Colecciones Exclusivas": [
      {
        nombre: "Ediciones Temáticas (bandas musicales, superhéroes, etc.)",
        description:
          "Colecciones limitadas inspiradas en temporadas, eventos o tradiciones culturales.",
        precio: { COP: 100000, USD: 25 },
      },
      {
        nombre: "Colaboraciones Exclusivas",
        description:
          "Productos diseñados en conjunto con artistas o marcas locales, únicos y llenos de estilo.",
        precio: { COP: 100000, USD: 25 },
      },
    ],
  },
  theme: {
    primaryColor: "#001c30",
    secondaryColor: "#230043",
    thirdColor: "#fb34ab",
    fourthColor: "#bbbbc7",
    linkColor: "#e7e7e7",
    backgroundColor: "#e3e3d0",
    textColor: "#212529",
    urlLogo: "loretta3/logo_woutback.png",
    urlBack: "loretta3/back2.png",
    postBack: "linear-gradient(135deg, #fafafa 20%, #fd2e04 70%, #0a0000 10%)",
    postOne: {
      fontFamily: "'Nunito Sans', sans-serif",
      fontWeight: 800,
      margin: "0",
      textShadow:
        "0 0 5px #e0d9dd, 0 0 10px #d94bfc, 0 0 15px #fcf8f7, 0 0 20px #001c30",
      lineHeight: "1em",
    },
    postTwo: {
      fontFamily: "'Orbitron', sans-serif",
      margin: "0",
      color: "#001c30",
      textShadow:
        "0 0 5px #e0d9dd, 0 0 10px #d94bfc, 0 0 15px #fcf8f7, 0 0 20px #b1a6d2",
      fontWeight: 800,
    },
  },
  company: {
    name: "Artesanias Armadillo",
    whatsapp: "573011911727",
  },
  socialLinks: {
    float: {
      whatsapp:
        "https://api.whatsapp.com/send?phone=573011911727&text=Hola%21%20Quiero%20m%C3%A1s%20informaci%C3%B3n",
    },
    footer: {
      facebook: "https://www.facebook.com/artesaniasarmadillozipa",
      instagram: "https://www.instagram.com/artesaniasarmadillo/",
      google: "https://maps.app.goo.gl/VJEKyVFbEd5UyhjG8",
    },
  },
  mailTo: {
    to: "artesaniasarmadillo@gmail.com",
    cc: "",
    bcc: "",
    message: {
      subject: "Contacto desde página web",
      text: "",
      html: "",
    },
  },
  contactForm: {
    name: "",
    email: "",
    phone: "",
    message: "",
  },
  message: null,
  loading: false,
  error: null,
};

const armadilloSlice = createSlice({
  name: "armadillo",
  initialState,
  reducers: {
    init(state) {
      state.contactForm = { name: "", email: "", phone: "", message: "" };
      state.message = null;
      state.error = null;
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchData.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchData.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(fetchData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || action.error.message;
      })
      .addCase(submitContactForm.pending, (state) => {
        state.loading = true;
      })
      .addCase(submitContactForm.fulfilled, (state) => {
        state.loading = false;
        state.contactForm = { name: "", email: "", phone: "", message: "" };
        state.message = "Mensaje enviado. Pronto nos comunicaremos contigo";
      })
      .addCase(submitContactForm.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || action.error.message;
      });
  },
});

export const { init } = armadilloSlice.actions;
export default armadilloSlice.reducer;
