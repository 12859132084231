// index.js
import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import store from "./store"; // Store centralizado
import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css"; // Bootstrap si es necesario
import "./site.css"; // Estilos globales
import { HelmetProvider } from "react-helmet-async";
// index.js
import "font-awesome/css/font-awesome.min.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <HelmetProvider>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </HelmetProvider>
  </Provider>
);
