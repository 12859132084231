import React, { Suspense, lazy } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import PrivacyPolicy from "./components/PrivacyPolicy";
import Wrapper from "./hub/Wrapper";

// Lazy load de los hubs
const Loretta3Home = lazy(() => import("./hub/loretta3/Loretta3Home"));
const PringamosaHome = lazy(() => import("./hub/la-pringamosa/PringamosaHome"));
const AshkaHome = lazy(() =>
  import("./hub/ashka-soluciones-financieras/old/AshkaHome")
);

const ArtesaniasArmadilloHome = lazy(() =>
  import("./hub/artesanias-armadillo/ArtesaniasArmadilloHome")
);

function App() {
  return (
    <BrowserRouter>
      <Wrapper>
        <Suspense fallback={<div>Cargando...</div>}>
          <Routes>
            <Route path="*" element={<Loretta3Home />} />
            <Route path="landing/la-pringamosa" element={<PringamosaHome />} />
            <Route
              path="landing/ashka-soluciones-financieras"
              element={<AshkaHome />}
            />
            <Route
              path="landing/artesanias-armadillo"
              element={<ArtesaniasArmadilloHome />}
            />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          </Routes>
        </Suspense>
      </Wrapper>
    </BrowserRouter>
  );
}

export default App;
