// hub/loretta3/Wrapper.js
import React from "react";
import PropTypes from "prop-types";
import styles from "./wrapper.module.css";

const Wrapper = ({ children, infoMessage, errorMessage }) => {
  return (
    <div className={styles.wrapper}>
      {infoMessage && <div className={styles.infoMessage}>{infoMessage}</div>}
      {errorMessage && (
        <div className={styles.errorMessage}>{errorMessage}</div>
      )}
      {children}
    </div>
  );
};

Wrapper.propTypes = {
  children: PropTypes.node.isRequired,
  themeStyle: PropTypes.object,
  infoMessage: PropTypes.string,
  errorMessage: PropTypes.string,
};

export default Wrapper;
