// store/pringamosaSlice.js
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { siteConstants } from "../assets/constants/site.constants";
import fire from "../fire";
import { getFirestore, collection, addDoc } from "firebase/firestore";

// Thunk para enviar el formulario de contacto, utilizando async/await con try/catch
export const submitContactForm = createAsyncThunk(
  `${siteConstants.PRINGAMOSA}/submitContactForm`,
  async ({ formData, mailTo }, { rejectWithValue }) => {
    try {
      const html = `
        <h1 style='color: #5e9ca0;'>Mensaje de <span style='color: #2b2301;'>${formData.name}</span> &lt;${formData.email}&gt;</h1>
        <h2 style='color: #2e6c80;'>Mensaje:</h2>
        <p>${formData.message}</p>
        <p><strong>Mensaje enviado desde su página web</strong></p>
      `;
      const payload = {
        ...mailTo,
        cc: formData.email,
        message: {
          ...mailTo.message,
          text: formData.message,
          html,
        },
        user: "PRINGAMOSA",
      };
      const db = getFirestore(fire.auth().app);
      const docRef = await addDoc(collection(db, "mailTo"), payload);
      return { ...payload, id: docRef.id };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Thunk de ejemplo (fetch) que retorna un array vacío (puedes adaptarlo según sea necesario)
export const fetchData = createAsyncThunk(
  `${siteConstants.TEMPATE}/fetchData`,
  async (payload, { rejectWithValue }) => {
    try {
      // Implementa la lógica de fetch según tus necesidades
      return [];
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const initialState = {
  id: "tempalte",
  name: "template | Soluciones IT",
  slogan: {
    one: "La Pringamosa",
    two: "¡Sabor, tradición y momentos inolvidables!",
  },
  type: "IT Company",
  title: "Desarrollo Web y Soluciones IT a Medida",
  seo: {
    title: "La Pringamosa | Restaurante Bar y Salón de Eventos en Zipaquirá",
    description:
      "Disfruta de La Pringamosa, el restaurante bar en la entrada de Zipaquirá. Ofrecemos comida típica, cocteles artesanales y espacios para eventos como matrimonios, cumpleaños y cenas románticas.",
    keywords:
      "restaurante en Zipaquirá, bar en Zipaquirá, salón de eventos Zipaquirá, comida típica colombiana, eventos empresariales, cenas románticas Zipaquirá, celebraciones Zipaquirá, La Pringamosa restaurante",
    url: "https://loretta3.com/landing/la-pringamosa",
    image: "https://loretta3.com/images/la-pringamosa.png",
    robots: "index, follow",
    author: "Loretta3",
    faviconUrls: {
      faviconIco: "/assets/images/favicons/la-pringamosa/favicon.ico",
      favicon32: "/assets/images/favicons/la-pringamosa/favicon-32x32.png",
      favicon16: "/assets/images/favicons/la-pringamosa/favicon-16x16.png",
      appleTouchIcon:
        "/assets/images/favicons/la-pringamosa/apple-touch-icon.png",
      android192:
        "/assets/images/favicons/la-pringamosa/android-chrome-192x192.png",
      android512:
        "/assets/images/favicons/la-pringamosa/android-chrome-512x512.png",
    },
  },
  nav: {
    home: { href: "menu", label: "La Pringamosa" },
    // about: { href: "about-us", label: "Nosotros" },
    products: { href: "menu", label: "Menu" },
    contact: { href: "contact", label: "Contactenos" },
  },
  post: {
    post: "La Pringamosa",
    postOne: "Restaurante - Bar",
    postTwo: "Salón de eventos",
    fontFamily: "Parisienne, cursive",
    fontWeight: 800,
  },
  aboutSimple: {
    meta: { name: "description" },
    copy: "Ubicado en el corazón del centro histórico de Zipaquirá, La Pringamosa fusiona la tradición culinaria colombiana con un ambiente acogedor y festivo. Ideal para disfrutar de comida típica y celebrar momentos inolvidables.",
  },
  about: {
    aboutUs: {
      name: "La Pringamosa",
      desc: "La Pringamosa nace sobre la Calle Primera, a pocos pasos de la icónica Catedral de Sal en Zipaquirá. Nuestro establecimiento combina la rica tradición gastronómica colombiana con un ambiente cálido y versátil, perfecto para cenas familiares, eventos corporativos, cumpleaños y celebraciones especiales.",
    },
    mission: {
      name: "Misión",
      desc: "Brindar a nuestros clientes una experiencia culinaria auténtica y un espacio único, donde la tradición y la innovación se unen para crear momentos memorables en cada visita.",
    },
    vision: {
      name: "Visión",
      desc: "Ser reconocidos como el referente gastronómico y de eventos en Zipaquirá, promoviendo la cultura colombiana y ofreciendo un servicio de excelencia que inspire a celebrar la vida.",
    },
  },

  theme: {
    primaryColor: "#001c30", // Color principal para botones y elementos destacados
    secondaryColor: "#EF32D9", // Complementario para detalles
    thirdColor: "#2c0119", // Para resaltar títulos y hover (por ejemplo, links o botones)
    fourthColor: "#f4f4f2", // Fallback
    linkColor: "#FFF", // Enlaces
    navlinkColor: "#f8f9fa", // Enlaces
    backgroundColor: "#FFD230", // Fondo principal (amarillo característico)
    textColor: "#490404", // Texto en blanco
    contactTextColor: "#490404", // Texto en blanco
    postTextColor: "#FFF", // Texto en blanco
    backgroundUrl:
      "url('https://firebasestorage.googleapis.com/v0/b/loretta3.firebasestorage.app/o/pringamosa-back-2.mp4?alt=media&token=f738d85d-bcba-4173-bc27-e0e9049bac16')",
    postOne: {
      fontFamily: "'Parisienne', cursive",
      fontWeight: 800,
      margin: "0",
      textShadow:
        "0 0 5px #e0d9dd, 0 0 10px #EF32D9, 0 0 15px #A30162, 0 0 20px #b1a6d2",
      lineHeight: "1em",
    },
    postTwo: {
      fontFamily: "'Orbitron', sans-serif",
      margin: "0",
      color: "#2c0119",
      textShadow: "0 4px 4px #FFD230",
      fontWeight: 800,
    },
    postAbout: {
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      color: "#ffddc4",
    },
  },
  company: {
    name: "La Pringamosa",
    whatsapp: "573042433341",
  },
  socialLinks: {
    float: {
      "map-marker": "https://maps.app.goo.gl/5nrB6mhSd2WiFo4W7",
      whatsapp:
        "https://api.whatsapp.com/send?phone=3042433341&text=Hola%21%20Quiero%20m%C3%A1s%20informaci%C3%B3n",
    },
    contact: {
      facebook: "https://www.facebook.com/lapringamosa.rest",
      instagram: "https://www.instagram.com/lapringamosazipa/",
    },
    footer: {
      facebook: "https://www.facebook.com/lapringamosa.rest",
      instagram: "https://www.instagram.com/lapringamosazipa/",
      linkedin: "",
      twitter: "",
      youtube: "",
      tiktok: "",
      "map-marker": "https://maps.app.goo.gl/5nrB6mhSd2WiFo4W7",
    },
    locationEmbed:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3974.507569498873!2d-74.00759022627875!3d5.021093338799404!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e407127bdd23b4d%3A0x231a2483348b299d!2sRestaurante%20La%20Pringamosa!5e0!3m2!1ses-419!2sco!4v1740671249942!5m2!1ses-419!2sco",
  },
  menu: {
    Desayunos: [
      { nombre: "Caldo de costilla", precio: { COP: 10000, USD: 3 } },
      {
        nombre: "Caldo de pescado (en agua o en leche)",
        precio: { COP: 10000, USD: 3 },
      },
      {
        nombre: "Huevos al gusto (fritos, batidos o pericos)",
        precio: { COP: 8000, USD: 3 },
      },
      {
        nombre: "Huevos rancheros, aloha o con mazorca",
        precio: { COP: 10000, USD: 3 },
      },
      { nombre: "Changua", precio: { COP: 9500, USD: 3 } },
      { nombre: "Huevos con arroz", precio: { COP: 10000, USD: 3 } },
      { nombre: "Tamal", precio: { COP: 10000, USD: 3 } },
      { nombre: "Porción de arroz", precio: { COP: 3000, USD: 1 } },
      { nombre: "Porción de queso", precio: { COP: 3500, USD: 1 } },
      { nombre: "Porción de fruta", precio: { COP: 4000, USD: 1 } },
    ],
    "Combos de desayuno": [
      {
        nombre: "Combo 1",
        descripcion:
          "Huevos al gusto, arepa o pan, bebida (café, chocolate, tinto, aromática, aguapanela)",
        precio: { COP: 13000, USD: 2.89 },
      },
      {
        nombre: "Combo 2",
        descripcion: "Caldo de costilla, huevos al gusto, arepa o pan, bebida",
        precio: { COP: 18000, USD: 4.0 },
      },
      {
        nombre: "Combo 3",
        descripcion: "Tamal, pan o arepa, bebida",
        precio: { COP: 13500, USD: 3.0 },
      },
      {
        nombre: "Combo 4",
        descripcion: "Calentado, chorizo, huevos al gusto, arepa o pan, bebida",
        precio: { COP: 18000, USD: 4.0 },
      },
      {
        nombre: "Combo 5 (Moñona)",
        descripcion:
          "Arroz, carne asada o bistec, papa francesa o chorreada, huevo, arepa o pan, bebida",
        precio: { COP: 19500, USD: 4.33 },
      },
    ],
    Entradas: [
      { nombre: "Morcilla", precio: { COP: 16000, USD: 4 } },
      { nombre: "Patacones con Hogao", precio: { COP: 16000, USD: 4 } },
      { nombre: "Nuggets", precio: { COP: 18000, USD: 5 } },
      { nombre: "Chicharrones", precio: { COP: 18000, USD: 5 } },
      { nombre: "Empanadas", precio: { COP: 18000, USD: 5 } },
    ],
    Parrillada: [
      {
        nombre: "Tabla de Carnes",
        descripcion: "Lomo de cerdo, res, pechuga, arroz, ensalada, papa",
        precio: { COP: 38000, USD: 8.44 },
      },
      {
        nombre: "Chuleta o Lomo de Cerdo",
        descripcion: "250 grs, arroz, ensalada, papa",
        precio: { COP: 42000, USD: 9.33 },
      },
      {
        nombre: "Pechuga a la Plancha",
        descripcion: "250 grs, arroz, ensalada, papa",
        precio: { COP: 33000, USD: 7.33 },
      },
      {
        nombre: "Churrasco",
        descripcion: "250 grs, arroz, ensalada, papa",
        precio: { COP: 40000, USD: 8.89 },
      },
      {
        nombre: "Costillas de Cerdo",
        descripcion: "350 grs, en salsa, arroz, ensalada, papa",
        precio: { COP: 38000, USD: 8.44 },
      },
      {
        nombre: "Alitas",
        descripcion: "Pollo en salsa, arroz, ensalada, papa",
        precio: { COP: 38000, USD: 8.44 },
      },
    ],
    Adiciones: [
      {
        nombre: "Salsa de Champiñon",
        precio: { COP: 8000, USD: 1.78 },
      },
      {
        nombre: "Gratinado",
        precio: { COP: 8000, USD: 1.78 },
      },
      {
        nombre: "Azteca",
        precio: { COP: 8000, USD: 1.78 },
      },
      {
        nombre: "Meriland",
        precio: { COP: 8000, USD: 1.78 },
      },
      {
        nombre: "Arroz",
        precio: { COP: 5000, USD: 1.11 },
      },
      {
        nombre: "Queso",
        precio: { COP: 4000, USD: 0.89 },
      },
      {
        nombre: "Fruta",
        precio: { COP: 3500, USD: 0.78 },
      },
    ],
    "Típica Colombiana": [
      {
        nombre: "Huesitos de Marrano",
        descripcion:
          "300 grs de huesitos de marrano, yuca, papa en salsa criolla, arroz, ensalada",
        precio: { COP: 45000, USD: 10.0 },
      },
      {
        nombre: "Lengua en Salsa",
        descripcion: "350 grs de lengua, yuca, papa, arroz, ensalada",
        precio: { COP: 45000, USD: 10.0 },
      },
      {
        nombre: "Sobrebarriga Zipaquireña",
        descripcion: "250 grs de sobrebarriga, yuca, papa, arroz, ensalada",
        precio: { COP: 45000, USD: 10.0 },
      },
      {
        nombre: "Bistec a Caballo",
        descripcion:
          "250 grs de carne en salsa bistec, huevo, papa, arroz, ensalada",
        precio: { COP: 40000, USD: 8.89 },
      },
      {
        nombre: "Bandeja Paisa",
        descripcion:
          "Frijoles, arroz, carne molida, huevo, morcilla, chorizo, tocino, plátano, aguacate, arepa",
        precio: { COP: 37000, USD: 8.22 },
      },
      {
        nombre: "Frijolada",
        descripcion:
          "Cazuela de frijol con chorizo y tocino, patacón, lomo de cerdo, arroz y aguacate",
        precio: { COP: 35000, USD: 7.78 },
      },
      {
        nombre: "Ajiaco Especial",
        descripcion:
          "Sopa de ajiaco, pierna pernil en salsa criolla, papa, mazorca, arroz, aguacate, crema de leche y alcaparras",
        precio: { COP: 40000, USD: 8.89 },
      },
    ],
    Pescados: [
      {
        nombre: "Bagre",
        descripcion:
          "350 gramos de bagre (en salsa o dorado), arroz, ensalada y papa (francesa, criolla o salada)",
        precio: { COP: 40000, USD: 8.89 },
      },
      {
        nombre: "Trucha",
        descripcion: "500 gramos, arroz, ensalada, papa",
        precio: { COP: 45000, USD: 10.0 },
      },
      {
        nombre: "Mojarra",
        descripcion: "500 gramos, arroz, ensalada, papa",
        precio: { COP: 45000, USD: 10.0 },
      },
    ],
    Cazuelas: [
      {
        nombre: "Cazuela de Mariscos",
        descripcion: "Incluye arroz, aguacate y patacón",
        precio: { COP: 45000, USD: 10.0 },
      },
    ],
    Patacones: [
      {
        nombre: "Patacón Sencillo",
        descripcion:
          "Patacón, maíz, carne desmechada, pollo desmechado, queso, papas chip y salsas al gusto",
        precio: {
          COP: 23000,
          USD: 5.11,
          COMBO_COP: 28000,
          COMBO_USD: 6.22,
        },
      },
      {
        nombre: "Patacón Aloha",
        descripcion:
          "Patacón, maíz, carne desmechada, pollo desmechado, papas chip, piña, jamón, queso y salsas al gusto",
        precio: {
          COP: 25000,
          USD: 5.56,
          COMBO_COP: 30000,
          COMBO_USD: 6.67,
        },
      },
      {
        nombre: "Patacón Ranchero",
        descripcion:
          "Patacón, maíz, carne desmechada, pollo desmechado, papas chip, queso, chorizo, tocineta, y salsas al gusto",
        precio: {
          COP: 26000,
          USD: 5.78,
          COMBO_COP: 32000,
          COMBO_USD: 7.11,
        },
      },
      {
        nombre: "Patacón Azteca",
        descripcion:
          "Patacón, maíz, carne desmechada, pollo desmechado, papas chip, queso, pico de gallo, guacamole, tostacos, y salsas al gusto",
        precio: {
          COP: 26000,
          USD: 5.78,
          COMBO_COP: 32000,
          COMBO_USD: 7.11,
        },
      },
    ],
    "Hamburguesas Artesanales": [
      {
        nombre: "Hamburguesa Artesanal",
        descripcion:
          "Pan artesanal, papas chip, cebolla, tomate, lechuga, carne artesanal, queso y salsas al gusto",
        precio: {
          COP: 18000,
          USD: 4.0,
          COMBO_COP: 23000,
          COMBO_USD: 5.11,
        },
      },
      {
        nombre: "Hamburguesa Aloha",
        descripcion:
          "Pan artesanal, papas chip, lechuga, carne artesanal, queso, jamón, piña natural y salsas al gusto",
        precio: {
          COP: 23000,
          USD: 5.11,
          COMBO_COP: 27000,
          COMBO_USD: 6.0,
        },
      },
      {
        nombre: "Hamburguesa Azteca",
        descripcion:
          "Pan artesanal, papas chip, cebolla, tomate, lechuga, carne artesanal, queso, pico de gallo, guacamole, tostones y salsas al gusto",
        precio: {
          COP: 23000,
          USD: 5.11,
          COMBO_COP: 27000,
          COMBO_USD: 6.0,
        },
      },
      {
        nombre: "Hamburguesa Ranchera",
        descripcion:
          "Pan artesanal, papas chip, cebolla, tomate, lechuga, carne artesanal, queso, chorizo, tocineta ahumada y salsas al gusto",
        precio: {
          COP: 23000,
          USD: 5.11,
          COMBO_COP: 27000,
          COMBO_USD: 6.0,
        },
      },
      {
        nombre: "Hamburguesa Champiñón",
        descripcion:
          "Pan artesanal, papas chip, cebolla, tomate, lechuga, carne artesanal, queso, champiñón y salsas al gusto",
        precio: {
          COP: 23000,
          USD: 5.11,
          COMBO_COP: 27000,
          COMBO_USD: 6.0,
        },
      },
      {
        nombre: "Hamburguesa Valluna",
        descripcion:
          "Patacón, papas chip, cebolla, tomate, lechuga, carne artesanal, queso y salsas al gusto",
        precio: {
          COP: 23000,
          USD: 5.11,
          COMBO_COP: 27000,
          COMBO_USD: 6.0,
        },
      },
      {
        nombre: "Hamburguesa Criolla",
        descripcion:
          "Patacón, papas chip, cebolla, tomate, lechuga, carne artesanal, queso, maíz y tortilla de doritos",
        precio: {
          COP: 23000,
          USD: 5.11,
          COMBO_COP: 27000,
          COMBO_USD: 6.0,
        },
      },
      {
        nombre: "Hamburguesa Bistec",
        descripcion:
          "Pan artesanal, papas chip, sofrito de cebolla, tomate, lechuga, carne artesanal, huevo frito, queso y salsas al gusto",
        precio: {
          COP: 23000,
          USD: 5.11,
          COMBO_COP: 27000,
          COMBO_USD: 6.0,
        },
      },
      {
        nombre: "Hamburguesa Muisca",
        descripcion:
          "Arepa choclo, papas chip, lechuga, carne artesanal, queso, hogao y salsas al gusto",
        precio: {
          COP: 23000,
          USD: 5.11,
          COMBO_COP: 27000,
          COMBO_USD: 6.0,
        },
      },
      {
        nombre: "Hamburguesa Doble Carne",
        descripcion:
          "Pan artesanal, papas chip, cebolla, tomate, lechuga, doble carne artesanal, queso y salsas al gusto",
        precio: {
          COP: 23000,
          USD: 5.11,
          COMBO_COP: 27000,
          COMBO_USD: 6.0,
        },
      },
    ],
    Salchipapas: [
      {
        nombre: "Salchipapa Sencilla",
        descripcion:
          "Salchicha o chorizo, papa francesa, papitas chip, queso y salsas al gusto",
        precio: {
          COP: 12000,
          USD: 2.67,
          COMBO_COP: 16000,
          COMBO_USD: 3.56,
        },
      },
      {
        nombre: "Salchipapa Especial",
        descripcion:
          "Salchicha o chorizo, papa francesa, papitas chip, pollo desmechado, carne desmechada, queso y salsas al gusto",
        precio: {
          COP: 17000,
          USD: 3.78,
          COMBO_COP: 30000,
          COMBO_USD: 6.67,
        },
      },
    ],
    "Perros Calientes": [
      {
        nombre: "Perro Sencillo",
        descripcion:
          "Pan artesanal, salchicha americana, papitas chip, queso y salsas al gusto",
        precio: {
          COP: 12000,
          USD: 2.67,
          COMBO_COP: 17000,
          COMBO_USD: 3.78,
        },
      },
      {
        nombre: "Perro En Champiñones",
        descripcion:
          "Pan artesanal, salchicha americana, papitas chip, queso, salsa de champiñones y salsas al gusto",
        precio: {
          COP: 14000,
          USD: 3.11,
          COMBO_COP: 18000,
          COMBO_USD: 4.0,
        },
      },
      {
        nombre: "Perro Aloha",
        descripcion:
          "Pan artesanal, salchicha americana, papitas chip, queso, piña en trozos, jamón y salsas al gusto",
        precio: {
          COP: 14000,
          USD: 3.11,
          COMBO_COP: 18000,
          COMBO_USD: 4.0,
        },
      },
      {
        nombre: "Perro Ranchero",
        descripcion:
          "Pan artesanal, salchicha americana, papitas chip, queso, chorizo, tocineta ahumada y salsas al gusto",
        precio: {
          COP: 14000,
          USD: 3.11,
          COMBO_COP: 18000,
          COMBO_USD: 4.0,
        },
      },
      {
        nombre: "Perro Azteca",
        descripcion:
          "Pan artesanal, salchicha americana, papitas chip, queso, pico de gallo, guacamole, tostitos y salsas al gusto",
        precio: {
          COP: 14000,
          USD: 3.11,
          COMBO_COP: 18000,
          COMBO_USD: 4.0,
        },
      },
    ],
    Mazorcadas: [
      {
        nombre: "Mazorcada Sencilla",
        descripcion:
          "Maíz, pollo desmechado, carne desmechada, papitas chip, queso y salsas al gusto.",
        precio: { COP: 16000, USD: 4, COMBO_COP: 20000, COMBO_USD: 5 },
      },
      {
        nombre: "Mazorcada Aloha",
        descripcion:
          "Maíz, pollo desmechado, carne desmechada, papitas chip, piña, jamón, queso y salsas al gusto.",
        precio: {
          COP: 17000,
          USD: 4.25,
          COMBO_COP: 22000,
          COMBO_USD: 5.5,
        },
      },
      {
        nombre: "Mazorcada Ranchera",
        descripcion:
          "Maíz, pollo desmechado, carne desmechada, papitas chip, chorizo, tocineta ahumada, queso y salsas al gusto.",
        precio: {
          COP: 17000,
          USD: 4.25,
          COMBO_COP: 22000,
          COMBO_USD: 5.5,
        },
      },
      {
        nombre: "Mazorcada Azteca",
        descripcion:
          "Maíz, pollo desmechado, carne desmechada, papitas chip, pico de gallo, guacamole, tostacos, queso y salsas al gusto.",
        precio: {
          COP: 17000,
          USD: 4.25,
          COMBO_COP: 22000,
          COMBO_USD: 5.5,
        },
      },
    ],
    Lasagnas: [
      {
        nombre: "Lasaña Carne o Pollo",
        descripcion: "",
        precio: { COP: 18000, USD: 4.5 },
      },
      {
        nombre: "Lasaña Mixta",
        descripcion: "",
        precio: { COP: 19000, USD: 4.75 },
      },
      {
        nombre: "Lasaña Pollo Champiñón",
        descripcion: "",
        precio: { COP: 18000, USD: 4.5 },
      },
      {
        nombre: "Lasaña Vegetariana",
        descripcion: "Tomate, pimentón, cebolla, maíz, queso y champiñón.",
        precio: { COP: 19000, USD: 4.75 },
      },
      {
        nombre: "Lasaña Carbonara",
        descripcion: "Pollo, tocineta, salsa bechamel.",
        precio: { COP: 18000, USD: 4.5 },
      },
      {
        nombre: "Lasaña Paisa",
        descripcion: "Carne, chicharrón, plátano maduro, chorizo y queso.",
        precio: { COP: 19000, USD: 4.75 },
      },
      {
        nombre: "Lasaña Azteca",
        descripcion:
          "Carne, pollo, pico de gallo, guacamole, nachos, maíz y queso.",
        precio: { COP: 19000, USD: 4.75 },
      },
    ],

    "Bebidas Calientes": [
      { nombre: "Tinto", precio: { COP: 2000, USD: 1 } },
      { nombre: "Café / Latte", precio: { COP: 3000, USD: 1 } },
      { nombre: "Aromática de Frutas", precio: { COP: 4000, USD: 2 } },
      { nombre: "Aromática Sencilla", precio: { COP: 3000, USD: 1 } },
      { nombre: "Canelazo / Carajillo", precio: { COP: 7000, USD: 3 } },
      { nombre: "Vino Caliente", precio: { COP: 8000, USD: 3 } },
      { nombre: "Café Frappé", precio: { COP: 4000, USD: 2 } },
      { nombre: "Milo Caliente / Frío", precio: { COP: 5000, USD: 2 } },
      { nombre: "Capuccino Baileys", precio: { COP: 8000, USD: 3 } },
      {
        nombre: "Aguapanela con Almojábana",
        precio: { COP: 12000, USD: 3 },
      },
      {
        nombre: "Aromática Agua Bendita (De la casa)",
        precio: { COP: 10000, USD: 3 },
      },
    ],
    "Jugos Naturales": [
      {
        nombre: "Jugos Naturales en Agua",
        descripcion:
          "Mora, mango, lulo, maracuyá, guanábana, fresa, frutos rojos",
        precio: { COP: 8000, USD: 1.78 },
      },
      {
        nombre: "Jugos Naturales en Leche",
        descripcion:
          "Mora, mango, lulo, maracuyá, guanábana, fresa, frutos rojos",
        precio: { COP: 9000, USD: 2.0 },
      },
    ],
    Limonadas: [
      { nombre: "Limonada Natural", precio: { COP: 6000, USD: 2 } },
      { nombre: "Limonada de Panela", precio: { COP: 7000, USD: 2 } },
      { nombre: "Limonada Cerezada", precio: { COP: 9000, USD: 3 } },
      {
        nombre: "Limonada de Hierbabuena",
        precio: { COP: 9000, USD: 3 },
      },
      { nombre: "Limonada de Coco", precio: { COP: 9000, USD: 3 } },
      { nombre: "Limonada de Sandía", precio: { COP: 9000, USD: 3 } },
      { nombre: "Limonada Azul", precio: { COP: 11000, USD: 3 } },
    ],
    "Otras Bebidas": [
      {
        nombre: "Gaseosa Personal 250ml",
        precio: { COP: 3500, USD: 1 },
      },
      { nombre: "Gaseosa 1.5lts", precio: { COP: 8000, USD: 3 } },
      { nombre: "Cerveza Nacional", precio: { COP: 5000, USD: 2 } },
      { nombre: "Coronita", precio: { COP: 9000, USD: 3 } },
      {
        nombre: "Cerveza Club Colombia",
        precio: { COP: 6000, USD: 2 },
      },
      { nombre: "Cerveza Light", precio: { COP: 6000, USD: 2 } },
      { nombre: "Cerveza Importada", precio: { COP: 12000, USD: 4 } },
      { nombre: "Bretaña", precio: { COP: 5000, USD: 2 } },
    ],
    Cocteles: [
      { nombre: "Mojito", precio: { COP: 25000, USD: 6 } },
      { nombre: "Daikiri de Fresa", precio: { COP: 25000, USD: 6 } },
      { nombre: "Cuba Libre", precio: { COP: 25000, USD: 6 } },
      { nombre: "Piña Colada", precio: { COP: 25000, USD: 6 } },
      { nombre: "Coco Loco", precio: { COP: 25000, USD: 6 } },
      { nombre: "Tom Collins", precio: { COP: 25000, USD: 6 } },
      {
        nombre: "Copa de Vino Tinto / Blanco",
        precio: { COP: 13000, USD: 3 },
      },
    ],
  },
  products: {},
  customers: [],
  mailTo: {
    to: "lapringamosa.gerencia@gmail.com",
    cc: "",
    bcc: "",
    message: {
      subject: "Cotacto desde sitio web",
      text: "",
      html: "",
    },
  },
  contactForm: {
    name: "",
    email: "",
    phone: "",
    message: "",
  },
  message: null,
  loading: false,
  error: null,
};

const pringamosaSlice = createSlice({
  name: "pringamosa",
  initialState,
  reducers: {
    init(state) {
      state.contactForm = { name: "", email: "", phone: "", message: "" };
      state.message = null;
      state.error = null;
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchData.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchData.fulfilled, (state) => {
        state.loading = false;
        state.message = "Mensaje enviado. Pronto nos comunicaremos contigo";
      })
      .addCase(fetchData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || action.error.message;
      })
      .addCase(submitContactForm.pending, (state) => {
        state.loading = true;
      })
      .addCase(submitContactForm.fulfilled, (state) => {
        state.loading = false;
        state.contactForm = { name: "", email: "", phone: "", message: "" };
        state.message = "Mensaje enviado. Pronto nos comunicaremos contigo";
      })
      .addCase(submitContactForm.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || action.error.message;
      });
  },
});

export const { init } = pringamosaSlice.actions;
export default pringamosaSlice.reducer;
